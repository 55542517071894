.teams-list__item {
  display: flex;
  margin: 10px 0;
  color: #000;
}

.teams-list__logo {
  max-width: 40px;
  max-height: 40px;
}

.teams-list__team-name {
  margin: 0;
  margin-left: 10px;
  line-height: 40px;
}
