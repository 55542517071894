.cups__heading {
  padding: 10px;
  background-color: #fafafa;
  font-size: 24px;
  font-weight: 500;
}

.cups__item {
  display: block;
  color: #333;
  margin: 20px 0;
  padding: 20px 10px;
  background-color: #f7f7f7;

  h3 {
    flex: 0.95;
    margin: 10px 0;
    font-weight: 500;
    line-height: 1.1;
    font-size: 18px;
  }

  &-section {
    display: flex;
    flex: 1.05;
    color: #222;
    font: bold 14px/18px 'Roboto', sans-serif;

    & > p {
      width: 33%;
    }

    &:hover > h3 {
      color: #ff5400;
    }
  }

  &-info {
    border-right: 1px solid #d9dde0;
    margin: 10px 0;
    padding: 0 20px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
    }
  }

  &-secondary {
    margin: 0;
    color: #7f8992;
    font: 12px/16px 'Roboto',sans-serif;
  }
}

@media (min-width: 768px) {
  .cups__item {
    display: flex;
    justify-content: space-between;
  }
}
