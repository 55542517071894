.pagination__container {
  display: flex;
  justify-content: center;
  margin: 15px;
}

.pagination {
  button {
    background-color: #fff;
    padding: 10px 20px;
    border: none;
    border-top: 2px solid #016bbf;
    border-bottom: 2px solid #016bbf;
    border-right: 1px solid #016bbf;

    &:first-child {
      border-left: 2px solid #016bbf;
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-right: 2px solid #016bbf;
      border-radius: 0 5px 5px 0;
    }
  }
}
