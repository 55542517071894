.chess {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      background-color: #e6eeee;
    }
  }

  tr:nth-child(2n) {
    background-color: #e6eeee;
  }

  td {
    padding: 10px;
    font-size: 15px;
    line-height: 15px;
    border: 2px solid #eee;
    text-align: center;
    p {
      margin: 0;
    }

    &.chess__team-name {
      text-align: left;
    }
  }
}

.chess__blank {
  background-color: #b6bbcb;
  border: 2px solid #b6bbcb;
}
