.notes {
  min-width: 250px;
}

.notes__header {
  color: #000;
}

.notes__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.notes__date {
  color: #999;
  font-size: 13px;
}

.notes-list {
  &__date {
    margin: 0;
    font-size: 13px;
  }

  &__content {
    margin: 0;
    padding-top: 7px;
  }

  &__content--link {
    color: #1488CC;
    text-decoration: underline;
  }
}

.notes-list__item {
  padding: 10px;
  border-bottom: 1px solid #333;

  &:nth-child(2n+1) {
    background-color: #fafafa;
  }

}
