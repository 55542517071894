.news-page__item {
  color: #333;
  background-color: #f7f7f7;
  margin-bottom: 20px;
}

.news-page__img {
  width: 250px;
  height: 150px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.news-page__header {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0px 10px;
  color: #333;
  font-size: 20px;
  font-weight: 500;
}

.news-page__text {
  padding: 0 10px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .news-page__item {
    display: flex;
    flex-shrink: 0;
  }

  .news-page__img-wrapper {
    max-width: 30%;
  }
}
