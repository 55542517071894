.main {
  margin: 0 auto;
  min-height: 90vh;
  box-sizing: content-box;
  padding: 20px 10px;
  background-color: #fff;
}

@media (min-width: 768px) {
  .main {
    max-width: 750px;
  }
}

@media (min-width: 991px) {
  .main {
    max-width: 950px;
  }

  .main__special {
    display: flex;
  }

  .news__wrapper {
    flex: 75;
  }

  .notes {
    flex: 25;
  }
}

@media (min-width: 1200px) {
  .main {
    max-width: 1150px;
  }
}
