body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-nav__wrapper {
  position: relative;
  background-color: #016bbf; }

.main-nav__shadow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  pointer-events: none; }

.main-nav {
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin: 0 auto; }

.main-nav__menu-btn {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: transparent;
  outline: none;
  border: none; }

.main-nav__btn-icon {
  position: absolute;
  left: 12px;
  top: 22px;
  width: 25px;
  height: 4px;
  border: none;
  outline: none;
  background-color: #ffffff; }
  .main-nav__btn-icon::before, .main-nav__btn-icon::after {
    position: absolute;
    content: '';
    width: 25px;
    height: 4px;
    margin-left: 15px;
    margin-top: 22px;
    background-color: #ffffff; }
  .main-nav__btn-icon::before {
    top: -12px;
    left: -15px; }
  .main-nav__btn-icon::after {
    top: -31px;
    left: -15px; }

.main-nav__list {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none; }
  .main-nav__list--hidden {
    display: none; }
  .main-nav__list a {
    color: #fff;
    font-size: 15px; }

.main-nav__item {
  display: block;
  padding: 16px;
  background-color: #019bdd;
  border-bottom: 1px solid #fff; }

.main-nav__search {
  position: relative;
  width: 80%;
  padding-top: 8px;
  padding-bottom: 8px; }

.main-nav__search-input {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 6px 40px 6px 12px; }

.main-nav__search-btn {
  position: absolute;
  top: 13px;
  right: 0;
  padding: 0;
  background-color: transparent;
  border: none; }
  .main-nav__search-btn svg {
    fill: #333; }

@media (min-width: 768px) {
  .main-nav {
    max-width: 750px; }
  .main-nav__menu-btn {
    display: none; }
  .main-nav__list {
    position: static; }
  .main-nav__list--hidden {
    display: block; }
  .main-nav__item {
    display: inline-block;
    background-color: transparent;
    border: none; }
  .main-nav__search {
    width: auto;
    min-width: 300px; }
  .main-nav__search-btn {
    display: block;
    height: 100%;
    top: 0;
    width: 45px;
    font-size: 0;
    background-color: transparent;
    border: none;
    cursor: pointer; }
    .main-nav__search-btn svg {
      fill: #fff; }
    .main-nav__search-btn--dark svg {
      fill: #000; }
  .main-nav__search-input {
    display: none; }
    .main-nav__search-input--show {
      display: inline; } }

@media (min-width: 991px) {
  .main-nav {
    max-width: 970px; } }

@media (min-width: 1200px) {
  .main-nav {
    max-width: 1170px; } }

.news__block {
  display: flex;
  flex-direction: row; }

.news__column {
  width: 66%;
  margin: 0px 2%; }

.anounce__column {
  width: 26%;
  margin: 0px 2%; }

.announce__date, .news__date {
  float: right;
  color: grey;
  font-size: 14px; }

.news__image {
  max-width: 100%;
  height: auto;
  max-height: 300px; }

.news__left {
  position: relative; }

.news__header {
  color: #000; }

.news__featured-header {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #333; }
  .news__featured-header:hover {
    color: orange; }

.news__featured-img {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 345px;
  height: 230px; }

.news__featured-link {
  position: absolute;
  right: 0;
  top: 0;
  padding: 3px 7px;
  font-size: 13px;
  font-weight: 500;
  background-color: #ff9c20;
  color: #fff; }

.news__item {
  margin-bottom: 5px; }

.news__item-img {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 150px;
  height: 100px;
  margin-top: 7px; }

.news__item-header {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0px 10px;
  color: #333;
  font-size: 16px;
  font-weight: 500; }

.news__item-text {
  margin: 0;
  padding: 0px 10px;
  color: #93999f;
  font-size: 12px; }

@media (min-width: 768px) {
  .news {
    display: flex; }
  .news__featured-img {
    position: relative; }
  .news__left {
    margin-right: 20px; }
  .news__item {
    display: flex; }
  .news__item-img {
    min-width: 150px; } }

.notes {
  min-width: 250px; }

.notes__header {
  color: #000; }

.notes__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.notes__date {
  color: #999;
  font-size: 13px; }

.notes-list__date {
  margin: 0;
  font-size: 13px; }

.notes-list__content {
  margin: 0;
  padding-top: 7px; }

.notes-list__content--link {
  color: #1488CC;
  text-decoration: underline; }

.notes-list__item {
  padding: 10px;
  border-bottom: 1px solid #333; }
  .notes-list__item:nth-child(2n+1) {
    background-color: #fafafa; }

.main {
  margin: 0 auto;
  min-height: 90vh;
  box-sizing: content-box;
  padding: 20px 10px;
  background-color: #fff; }

@media (min-width: 768px) {
  .main {
    max-width: 750px; } }

@media (min-width: 991px) {
  .main {
    max-width: 950px; }
  .main__special {
    display: flex; }
  .news__wrapper {
    flex: 75 1; }
  .notes {
    flex: 25 1; } }

@media (min-width: 1200px) {
  .main {
    max-width: 1150px; } }

.breadscrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  background-color: #f5f5f5; }

.breadscrumb__link {
  color: #337ab7;
  font-size: 14px;
  line-height: 20px; }

.breadscrumb__current {
  color: #777;
  font-size: 14px;
  line-height: 20px; }
  .breadscrumb__current--margin {
    margin-left: 10px;
    margin-right: 10px; }

.ads {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center; }

.ads__logo {
  display: flex;
  justify-content: center;
  flex-grow: 1; }

.ads__placeholder {
  max-width: 90vw;
  max-height: 80px; }
  .ads__placeholder img {
    max-width: 90vw;
    max-height: 80px;
    width: 100%;
    object-fit: contain; }

.ads__ad {
  width: 90%;
  max-height: 60px; }
  .ads__ad img {
    max-height: 60px;
    width: 100%;
    object-fit: contain; }

@media (min-width: 758px) {
  .ads {
    display: flex;
    justify-content: space-between; } }

.pagination__container {
  display: flex;
  justify-content: center;
  margin: 15px; }

.pagination button {
  background-color: #fff;
  padding: 10px 20px;
  border: none;
  border-top: 2px solid #016bbf;
  border-bottom: 2px solid #016bbf;
  border-right: 1px solid #016bbf; }
  .pagination button:first-child {
    border-left: 2px solid #016bbf;
    border-radius: 5px 0 0 5px; }
  .pagination button:last-child {
    border-right: 2px solid #016bbf;
    border-radius: 0 5px 5px 0; }

.cups__heading {
  padding: 10px;
  background-color: #fafafa;
  font-size: 24px;
  font-weight: 500; }

.cups__item {
  display: block;
  color: #333;
  margin: 20px 0;
  padding: 20px 10px;
  background-color: #f7f7f7; }
  .cups__item h3 {
    flex: 0.95 1;
    margin: 10px 0;
    font-weight: 500;
    line-height: 1.1;
    font-size: 18px; }
  .cups__item-section {
    display: flex;
    flex: 1.05 1;
    color: #222;
    font: bold 14px/18px 'Roboto', sans-serif; }
    .cups__item-section > p {
      width: 33%; }
    .cups__item-section:hover > h3 {
      color: #ff5400; }
  .cups__item-info {
    border-right: 1px solid #d9dde0;
    margin: 10px 0;
    padding: 0 20px; }
    .cups__item-info:first-child {
      padding-left: 0; }
    .cups__item-info:last-child {
      border-right: none; }
  .cups__item-secondary {
    margin: 0;
    color: #7f8992;
    font: 12px/16px 'Roboto',sans-serif; }

@media (min-width: 768px) {
  .cups__item {
    display: flex;
    justify-content: space-between; } }

.match__date {
  width: 12ch;
  flex-grow: 1; }

.match__time {
  width: 6ch; }

.match__teams {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-grow: 1; }

.match__league-name {
  width: 10%; }

.match__score {
  width: 11ch;
  text-align: center; }

.team__name {
  flex: 1 1;
  text-align: end; }

.team__name ~ .team__name {
  text-align: start; }

.match__staduim-name {
  width: 20%;
  flex-grow: 1; }

hr {
  margin: 0px 0;
  color: grey; }

.matches {
  margin-bottom: 20px; }

.matches__heading {
  display: block;
  margin-bottom: 5px;
  background-color: #016bbf;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  padding: 8px 15px;
  text-transform: uppercase; }

.matches__table {
  width: 100%;
  border-collapse: collapse; }

.matches__date {
  padding: 5px 10px;
  font-size: 13px;
  background-color: #eee; }

.matches__row {
  display: flex;
  border-bottom: 1px solid #eee; }
  .matches__row:last-child {
    border-bottom: none; }

.matches__item {
  display: flex;
  width: 45%;
  flex-grow: 1;
  justify-content: space-between;
  padding: 5px 10px; }

.matches__item--small {
  width: 10%;
  padding: 5px 10px;
  text-align: center; }

.matches__item-secondary {
  color: #999;
  font-size: 13px; }

.matches__link {
  color: #333;
  font-size: 13px;
  font-weight: 900; }
  .matches__link:hover {
    text-decoration: underline; }

.teams-list__item {
  display: flex;
  margin: 10px 0;
  color: #000; }

.teams-list__logo {
  max-width: 40px;
  max-height: 40px; }

.teams-list__team-name {
  margin: 0;
  margin-left: 10px;
  line-height: 40px; }

.match-table {
  width: 100%; }

.chess {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse; }
  .chess thead tr {
    background-color: #e6eeee; }
  .chess tr:nth-child(2n) {
    background-color: #e6eeee; }
  .chess td {
    padding: 10px;
    font-size: 15px;
    line-height: 15px;
    border: 2px solid #eee;
    text-align: center; }
    .chess td p {
      margin: 0; }
    .chess td.chess__team-name {
      text-align: left; }

.chess__blank {
  background-color: #b6bbcb;
  border: 2px solid #b6bbcb; }

.cup-page__tab-list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.cup-page__tab {
  display: inline-block;
  color: #222;
  padding-bottom: 10px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 8px;
  cursor: pointer; }
  .cup-page__tab:first-child {
    margin-left: 0; }
  .cup-page__tab:hover {
    color: #ff5400; }
  .cup-page__tab--active {
    border-bottom: 3px solid #ff5400; }

.table {
  width: 100%;
  border-collapse: collapse; }

.players__select-header {
  display: block;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 13px;
  font-weight: 400;
  color: #7f8992; }

.players__select {
  margin-left: 10px;
  padding: 4px 30px 4px 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 32px; }

.table__heading th {
  position: relative;
  padding: 13px 15px 7px;
  background-color: #e6eeee;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  color: #535f68;
  font-weight: 700;
  font-size: 13px;
  text-align: left; }

.table__item {
  border-bottom: 1px solid #dddddd; }
  .table__item td {
    padding: 8px 15px;
    font-size: 13px;
    color: #3d3d3d;
    font-weight: 400; }

.players__table-cell--secondary {
  display: none; }

th.table__cell--active {
  background-color: #8dbdd8; }

.asc::after {
  position: absolute;
  content: '\25B2';
  right: 10px;
  top: 10px; }

.desc::after {
  position: absolute;
  content: "\25BC";
  right: 10px;
  top: 10px; }

@media (min-width: 500px) {
  .players__table-cell--secondary {
    display: table-cell; } }

.info-header, .info-value {
  display: block; }

.info-header {
  color: grey;
  font-size: 14px; }

.info-block {
  margin: 8px; }

.news-page__item {
  color: #333;
  background-color: #f7f7f7;
  margin-bottom: 20px; }

.news-page__img {
  width: 250px;
  height: 150px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.news-page__header {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0px 10px;
  color: #333;
  font-size: 20px;
  font-weight: 500; }

.news-page__text {
  padding: 0 10px;
  font-size: 14px; }

@media (min-width: 768px) {
  .news-page__item {
    display: flex;
    flex-shrink: 0; }
  .news-page__img-wrapper {
    max-width: 30%; } }

.teams__header {
  display: flex; }

body {
  background-color: #EFEFF0; }

a {
  text-decoration: none; }

