.main-nav__wrapper {
  position: relative;
  background-color: #016bbf;
}

.main-nav__shadow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  pointer-events: none;
}

.main-nav {
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin: 0 auto;
}

.main-nav__menu-btn {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: transparent;
  outline: none;
  border: none;
}

.main-nav__btn-icon {
  position: absolute;
  left: 12px;
  top: 22px;
  width: 25px;
  height: 4px;
  border: none;
  outline: none;
  background-color: #ffffff;
  &::before, &::after {
    position: absolute;
    content: '';
    width: 25px;
    height: 4px;
    margin-left: 15px;
    margin-top: 22px;
    background-color: #ffffff;
  }

  &::before {
    top: -12px;
    left: -15px;
  }

  &::after {
    top: -31px;
    left: -15px;
  }
}

.main-nav__list {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;

  &--hidden {
    display: none;
  }

  a {
    color: #fff;
    font-size: 15px;
  }
}

.main-nav__item {
  display: block;
  padding: 16px;
  background-color: rgb(1, 155, 221);
  border-bottom: 1px solid #fff;
}

.main-nav__search {
  position: relative;
  width: 80%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.main-nav__search-input {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 6px 40px 6px 12px;
}

.main-nav__search-btn {
  position: absolute;
  top: 13px;
  right: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  svg {
    fill: #333;
  }
}

@media (min-width: 768px) {
  .main-nav {
    max-width: 750px;
  }

  .main-nav__menu-btn {
    display: none;
  }
  .main-nav__list {
    position: static;
  }

  .main-nav__list--hidden {
    display: block;
  }

  .main-nav__item {
    display: inline-block;
    background-color: transparent;
    border: none;
  }

  .main-nav__search {
    width: auto;
    min-width: 300px;
  }

  .main-nav__search-btn {
    display: block;
    height: 100%;
    top: 0;
    width: 45px;
    font-size: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    svg {
      fill: #fff;
    }

    &--dark {
      svg {
        fill: #000;
      }
    }
  }

  .main-nav__search-input {
    display: none;

    &--show {
      display: inline;
    }
  }
}

@media (min-width: 991px) {
  .main-nav {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .main-nav {
    max-width: 1170px;
  }
}
