.ads {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.ads__logo {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}
.ads__placeholder {
  max-width: 90vw;
  max-height: 80px;
  img {
    max-width: 90vw;
    max-height: 80px;
    width: 100%;
    object-fit: contain;
  }
}
.ads__ad {
  // flex-grow: 1;
  width: 90%;
  max-height: 60px;
  img {
    max-height: 60px;
    width: 100%;
    object-fit: contain;
  }
}

@media (min-width: 758px) {
  .ads {
    display: flex;
    justify-content: space-between;
  }

  .ads__ad {
    // width: 480px;
  }
}

@media (min-width: 991px) {
  .ads__ad {
    // max-width: 770px;
  }
}
