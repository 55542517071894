.news__block{
  display: flex;
  flex-direction: row;
}

.news__column{
  width: 66%;
  margin: 0px 2%;
}
.anounce__column{
  width: 26%;
  margin: 0px 2%;
}

.announce__date,.news__date{
  float: right;
  color: grey;
  font-size: 14px;
}

.news__image{
  max-width: 100%;
  height: auto;
  max-height: 300px;
}

.news__left {
  position: relative;
}

.news__header {
  color: #000;
}

.news__featured-header {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #333;

  &:hover {
    color: orange;
  }
}

.news__featured-img {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 345px;
  height: 230px;
}

.news__featured-link {
  position: absolute;
  right: 0;
  top: 0;
  padding: 3px 7px;
  font-size: 13px;
  font-weight: 500;
  background-color: #ff9c20;
  color: #fff;
}

.news__item {
  margin-bottom: 5px;
}

.news__item-img {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 150px;
  height: 100px;
  margin-top: 7px;
}

.news__item-header {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0px 10px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

.news__item-text {
  margin: 0;
  padding: 0px 10px;
  color: #93999f;
  font-size: 12px;
}

@media (min-width: 768px) {
  .news {
    display: flex;
  }

  .news__featured-img {
    position: relative;
  }

  .news__left {
    margin-right: 20px;
  }

  .news__item {
    display: flex;
  }

  .news__item-img {
    min-width: 150px;
  }
}
