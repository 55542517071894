.info-header,.info-value{
   display: block; 
}

.info-header{
    color: grey;
    font-size: 14px;
}
.info-block{
    margin: 8px;
}