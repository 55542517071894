.table {
  width: 100%;
  border-collapse: collapse;
}

.players__select-header {
  display: block;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 13px;
  font-weight: 400;
  color: #7f8992;
}

.players__select {
  margin-left: 10px;
  padding: 4px 30px 4px 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
}

.table__heading {
  th {
    position: relative;
    padding: 13px 15px 7px;
    background-color: #e6eeee;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    color: #535f68;
    font-weight: 700;
    font-size: 13px;
    text-align: left;
  }
}

.table__item {
  border-bottom: 1px solid rgb(221,221,221);
  td {
    padding: 8px 15px;
    font-size: 13px;
    color: #3d3d3d;
    font-weight: 400;
  }
}

.players__table-cell--secondary {
  display: none;
}

th.table__cell--active {
  background-color: #8dbdd8;
}

.asc::after {
  position: absolute;
  content: '\25B2';
  right: 10px;
  top: 10px;
}

.desc::after {
  position: absolute;
  content: "\25BC";
  right: 10px;
  top: 10px;
}

.players__table-cell--inactive::after {

}

@media (min-width: 500px) {
  .players__table-cell--secondary {
    display: table-cell;
  }
}
