.cup-page__tab-list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.cup-page__tab {
  display: inline-block;
  color: #222;
  padding-bottom: 10px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 8px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    color: #ff5400;
  }

  &--active {
    border-bottom: 3px solid #ff5400;
  }
}
