.match__date{
  width: 12ch;
  flex-grow: 1;
}
.match__time{
  width: 6ch;
}
.match__teams{
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}
.match__league-name{
  width: 10%;
}
.match__score{
  width: 11ch;
  text-align: center;
}
.team__name{
  flex: 1;
  text-align: end;
}
.team__name ~ .team__name{
  text-align: start;
}
.match__staduim-name{
  width: 20%;
  flex-grow: 1;
}
hr{
  margin: 0px 0;
  color: grey;
}
.matches {
  margin-bottom: 20px;
}

.matches__heading {
  display: block;
  margin-bottom: 5px;
  background-color: #016bbf;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  padding: 8px 15px;
  text-transform: uppercase;
}

.matches__table {
  width: 100%;
  border-collapse: collapse;
}

.matches__date {
  padding: 5px 10px;
  font-size: 13px;
  background-color: #eee;
}

.matches__row {
  display: flex;
  border-bottom: 1px solid #eee;
  &:last-child {
    border-bottom: none;
  }
}

.matches__item {
  display: flex;
  width: 45%;
  flex-grow: 1;
  justify-content: space-between;
  padding: 5px 10px;
}

.matches__item--small {
  width: 10%;
  padding: 5px 10px;
  text-align: center;
}

.matches__item-secondary {
  color: #999;
  font-size: 13px;
}

.matches__link {
  color: #333;
  font-size: 13px;
  font-weight: 900;
  &:hover {
    text-decoration: underline;
  }
}
