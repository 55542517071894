.breadscrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
}

.breadscrumb__link {
  color: #337ab7;
  font-size: 14px;
  line-height: 20px;
}

.breadscrumb__current {
  color: #777;
  font-size: 14px;
  line-height: 20px;

  &--margin {
    margin-left: 10px;
    margin-right: 10px;
  }
}
